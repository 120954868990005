import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { BasicDataGrid } from 'common/components';
import { transformDateFormat } from 'common/utils/dates';
import { numberFormat } from 'common/utils/helpers';
import { getDateFormatByLocale } from 'common/utils/helpers/multiregion';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import * as actions from '../actions';
import * as selectors from '../selectors';

import StatusIndicator from './StatusIndicator';

const ListItems = ({
    fetchLog,
    fetchReads,
    isLoading,
    match,
    reads,
    readsPagination,
    setLogModalOpen,
}) => {
    const { t } = useTranslation();
    const dateFormat = getDateFormatByLocale();
    const countryCurrencyLocale = getCountryCurrencyLocale();

    useEffect(() => {
        fetchReads(match?.params?.id);
    }, [match?.params?.id]);

    const onPageChange = (page) =>
        fetchReads(match?.params?.id, { page: page + 1 });

    const onPageSizeChange = (pageSize) =>
        fetchReads(match?.params?.id, { page: 1, pageSize });

    const handleOpenLog = () => {
        setLogModalOpen(true);
    };

    const onRowClick = (row) =>
        fetchLog(match?.params?.id, row?.id, handleOpenLog);

    const formatter = (val) =>
        numberFormat(val, {
            decimals: 2,
            locale: countryCurrencyLocale,
            style: 'decimal',
            unit: 'kWh',
        });

    const columns = [
        {
            field: 'date',
            headerName: t('Date'),
            sortable: false,
            valueGetter: (params) =>
                transformDateFormat(params.row?.date, dateFormat, 'yyyy-MM-dd'),
            width: 150,
        },
        {
            field: 'generation',
            flex: 1,
            headerName: t('Generation'),
            minWidth: 200,
            sortable: false,
            valueGetter: (params) => formatter(params.row?.generation),
        },
        {
            field: 'total_consumption',
            flex: 1,
            headerName: t('Total consumption'),
            minWidth: 200,
            sortable: false,
            valueGetter: (params) => formatter(params.row?.total_consumption),
        },
        {
            field: 'self_consumption',
            flex: 1,
            headerName: t('Self-consumption'),
            minWidth: 200,
            sortable: false,
            valueGetter: (params) => formatter(params.row?.self_consumption),
        },
        {
            field: 'grid_consumption',
            flex: 1,
            headerName: t('Grid consumption'),
            minWidth: 200,
            sortable: false,
            valueGetter: (params) => formatter(params.row?.self_consumption),
        },
        {
            field: 'status',
            headerName: t('Status'),
            renderCell: (params) => (
                <StatusIndicator status={params?.row?.status} />
            ),
            sortable: false,
            width: 150,
        },
    ];

    return (
        <>
            <BasicDataGrid
                columns={columns}
                isLoading={isLoading}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                onRowClick={onRowClick}
                page={readsPagination?.page - 1 || 0}
                pageSize={readsPagination?.pageSize || 25}
                paginationMode="server"
                rowCount={readsPagination?.total || 0}
                rows={reads}
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    isLoading: selectors.getReadsIsLoading,
    reads: selectors.getReads,
    readsPagination: selectors.getReadsPagination,
});

const mapDispatchToProps = (dispatch) => ({
    fetchLog: (projectId, readId, callback) =>
        dispatch(actions.fetchLog(projectId, readId, callback)),
    fetchReads: (id, params) => dispatch(actions.fetchReads(id, params)),
    setLogModalOpen: (isOpen) => dispatch(actions.setLogModalOpen(isOpen)),
});

ListItems.propTypes = {
    fetchLog: PropTypes.func,
    fetchReads: PropTypes.func,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
    reads: PropTypes.array,
    readsPagination: PropTypes.object,
    setLogModalOpen: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(ListItems);
