import API from '../../afterSalesApi';

const ENTITY = 'projects';

export const getProjects = ({
    companyId,
    credentialName,
    page,
    pageSize,
    providerId,
    status,
}) =>
    API.get(`/api/${ENTITY}/all-projects`, {
        params: {
            company_id: companyId,
            company_sk_name: credentialName,
            page,
            page_size: pageSize,
            provider_id: providerId,
            status,
        },
    });

export const getProjectReads = ({ page, pageSize, projectId, readId }) =>
    API.get(`/api/${ENTITY}/all-projects/${projectId}`, {
        params: { page, page_size: pageSize, read_id: readId },
    });

export const rewindProject = ({
    clearAllReads = false,
    companyId,
    finalDate,
    freq = 'D',
    id,
    initialDate,
}) =>
    API.post(`/api/${ENTITY}/${id}/rewind`, {
        clear_all_reads: clearAllReads,
        company_id: companyId,
        final_date: finalDate,
        freq,
        initial_date: initialDate,
    });

export const setProjectStatus = ({
    companyId,
    projectId,
    status,
    sunwiseProjectId,
}) =>
    API.post(
        `/api/${ENTITY}/set-status`,
        {
            company_id: companyId,
            project_id: projectId,
            status,
            sunwise_project_id: sunwiseProjectId,
        },
        { headers: { 'Content-Type': 'application/json' } }
    );
