import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    ReactHookFormInputDatePicker,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';
import { Button, Card, Grid, IconButton, Tooltip } from 'common/ui';

const History = ({
    control,
    disabled,
    handleDeleteSection,
    name,
    unitPrefix,
}) => {
    const { t } = useTranslation();
    const { fields, append, remove } = useFieldArray({ control, name });

    return (
        <>
            {fields.map((item, index) => (
                <Card key={item.id} sx={{ overflow: 'visible' }}>
                    <Grid alignItems="center" container spacing={2} p={2}>
                        <Grid
                            item
                            xs={12}
                            textAlign="center"
                            color="primary.main"
                            fontWeight="bold"
                        >
                            {index + 1}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ReactHookFormInputDatePicker
                                control={control}
                                disableFuture
                                disabled={disabled}
                                format="yyyy-MM-dd"
                                inputFormat="yyyy-MM-dd"
                                key={`${name}.${index}.initial_date`}
                                label={t('Initial date')}
                                name={`${name}.${index}.initial_date`}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <ReactHookFormInputDatePicker
                                control={control}
                                disableFuture
                                disabled={disabled}
                                format="yyyy-MM-dd"
                                inputFormat="yyyy-MM-dd"
                                key={`${name}.${index}.final_date`}
                                label={t('Final date')}
                                name={`${name}.${index}.final_date`}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <ReactHookFormIntlNumberInput
                                allowNegativeValue={false}
                                append={`${unitPrefix}W`}
                                control={control}
                                decimalsLimit={2}
                                disabled={disabled}
                                fullWidth
                                key={`${name}.${index}.demand`}
                                label={t('Demand')}
                                name={`${name}.${index}.demand`}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <ReactHookFormIntlNumberInput
                                allowNegativeValue={false}
                                append={`${unitPrefix}Wh`}
                                control={control}
                                decimalsLimit={2}
                                disabled={disabled}
                                fullWidth
                                key={`${name}.${index}.consumption`}
                                label={t('Energy')}
                                name={`${name}.${index}.consumption`}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <ReactHookFormIntlNumberInput
                                allowNegativeValue={false}
                                append="%"
                                control={control}
                                decimalsLimit={2}
                                disabled={disabled}
                                fullWidth
                                key={`${name}.${index}.fp`}
                                label={t('Power factor')}
                                max={100}
                                name={`${name}.${index}.fp`}
                            />
                        </Grid>

                        <Grid item xs={2} sm={1} ml="auto">
                            <Tooltip title={t('Delete')}>
                                <IconButton onClick={() => remove(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Card>
            ))}
            <Button
                disabled={disabled || fields?.length >= 12}
                onClick={() =>
                    append({
                        consumption: '',
                        demand: '',
                        final_date: '',
                        initial_date: '',
                        fp: '',
                    })
                }
                type="button"
            >
                {t('Add')}
            </Button>

            <Button
                color="error"
                onClick={() => handleDeleteSection('has_history', name)}
                sx={{ mt: 'auto' }}
                variant="outlined"
            >
                {t('Delete history')}
            </Button>
        </>
    );
};

History.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    handleDeleteSection: PropTypes.func,
    name: PropTypes.string,
    unitPrefix: PropTypes.string,
};

export default History;
