import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getProvidersModel = createSelector(
    getModel,
    (model) => model.providers
);

export const getProviders = createSelector(
    getProvidersModel,
    (model) => model.data || []
);

export const getIsFetchingProviders = createSelector(
    getProvidersModel,
    (model) => model.isFetching
);

export const getProvidersDictionary = createSelector(
    getProviders,
    (providers) =>
        providers.reduce((acc, provider) => {
            acc[provider.id] = provider;
            return acc;
        }, {}) || {}
);

export const getProvidersForSelect = createSelector(
    getProviders,
    (providers) => {
        const options = providers
            .filter((provider) => provider?.active)
            .map((provider) => ({ label: provider.name, value: provider.id }));

        return [{ label: '', options }];
    }
);

export const getProvidersForSelectWithoutGroup = createSelector(
    getProviders,
    (providers) =>
        providers
            .filter((provider) => provider?.active)
            .map((provider) => ({
                label: provider.name,
                value: provider.id,
            })) || []
);
