import { createSelector } from 'reselect';

import { DEFAULT_PAGE_SIZE, NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

/** Companies */
export const getCompaniesModel = createSelector(
    getModel,
    (model) => model.companies
);

export const getCompaniesData = createSelector(
    getCompaniesModel,
    (companies) => companies.data || []
);

export const getCompaniesForSelect = createSelector(getCompaniesData, (data) =>
    data.map((company) => ({ label: company.name, value: company.id }))
);

export const getCompaniesIsLoading = createSelector(
    getCompaniesModel,
    (companies) => companies.isLoading
);

/** Projects */
export const getProjectsModel = createSelector(
    getModel,
    (model) => model.projects
);

export const getProjectsData = createSelector(
    getProjectsModel,
    (projects) => projects.data
);

export const getProjects = createSelector(
    getProjectsData,
    (data) => data?.projects || []
);

export const getProjectsPagination = createSelector(
    getProjectsData,
    ({ pagination } = {}) => {
        return {
            page: pagination?.page || 1,
            pageSize: pagination?.pageSize || DEFAULT_PAGE_SIZE,
            total: pagination?.total || 0,
        };
    }
);

export const getProjectsIsLoading = createSelector(
    getProjectsModel,
    (projects) => projects.isLoading
);

export const getProjectsErrors = createSelector(
    getProjectsModel,
    (projects) => projects.errors
);

/** Rewind project */
export const getRewindProjectModel = createSelector(
    getModel,
    (model) => model.rewindProject
);

export const getRewindProjectIsLoading = createSelector(
    getRewindProjectModel,
    (rewindProject) => rewindProject.isLoading
);

export const getIsRewindModalOpen = createSelector(
    getModel,
    (model) => model.isOpenRewindModal
);

/** Status project */
export const getStatusProjectModel = createSelector(
    getModel,
    (model) => model.statusProject
);

export const getStatusProjectIsLoading = createSelector(
    getStatusProjectModel,
    (statusProject) => statusProject.isLoading
);

export const getIsStatusModalOpen = createSelector(
    getModel,
    (model) => model.isOpenStatusModal
);
