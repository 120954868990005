import update from 'immutability-helper';

import {
    FETCH_LOG,
    FETCH_LOG_FAILURE,
    FETCH_LOG_SUCCESS,
    FETCH_READS,
    FETCH_READS_FAILURE,
    FETCH_READS_SUCCESS,
    RESET,
    RESET_LOG,
    SET_LOG_MODAL_OPEN,
} from './actionTypes';

const INITIAL_STATE = {
    read_log: { data: {}, errors: null, isLoading: false },
    reads: {
        data: { pagination: {}, project: {}, reads: [] },
        errors: null,
        isLoading: false,
    },
    isLogModalOpen: false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_LOG:
            return update(state, {
                read_log: {
                    $merge: {
                        isLoading: true,
                        errors: null,
                    },
                },
            });

        case FETCH_LOG_FAILURE:
            return update(state, {
                read_log: {
                    $merge: {
                        isLoading: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_LOG_SUCCESS:
            return update(state, {
                read_log: {
                    $merge: {
                        isLoading: false,
                        data: action.payload,
                    },
                },
            });

        case FETCH_READS:
            return update(state, {
                reads: {
                    $merge: {
                        isLoading: true,
                        errors: null,
                    },
                },
            });

        case FETCH_READS_FAILURE:
            return update(state, {
                reads: {
                    $merge: {
                        isLoading: false,
                        errors: action.payload,
                    },
                },
            });

        case FETCH_READS_SUCCESS:
            return update(state, {
                reads: {
                    $merge: {
                        isLoading: false,
                        data: {
                            pagination: {
                                page: action.payload?.page,
                                pageSize: action.payload?.page_size,
                                total: action.payload?.total,
                            },
                            project: action.payload?.project,
                            reads: action.payload?.reads,
                        },
                    },
                },
            });

        case RESET:
            return INITIAL_STATE;

        case RESET_LOG:
            return update(state, {
                read_log: {
                    $set: INITIAL_STATE.read_log,
                },
            });

        case SET_LOG_MODAL_OPEN:
            return update(state, {
                isLogModalOpen: {
                    $set: action.payload,
                },
            });

        default:
            return state;
    }
}
