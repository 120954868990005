import { getProjects } from 'common/api/afterSales/projects';

import {
    FETCH_PROJECTS,
    FETCH_PROJECTS_FAILURE,
    FETCH_PROJECTS_SUCCESS,
} from '../actionTypes';

const addFilter = (filters, key, value) => {
    if (!key || (!value && value !== 0)) return;
    filters[key] = value;
};

export default (filters) => (dispatch) => {
    dispatch({ type: FETCH_PROJECTS });

    const params = {};
    const filterKeys = Object.keys(filters || {});

    for (const key of filterKeys) addFilter(params, key, filters[key]);

    getProjects(params)
        .then((response) =>
            dispatch({ payload: response?.data, type: FETCH_PROJECTS_SUCCESS })
        )
        .catch((error) =>
            dispatch({ payload: error, type: FETCH_PROJECTS_FAILURE })
        );
};
