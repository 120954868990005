import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import { get, isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { useFieldArray, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    ReactHookFormInput,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';
import {
    Alert,
    Box,
    Button,
    Grid,
    IconButton,
    MenuItem,
    Stack,
    Tooltip,
    Typography,
} from 'common/ui';
import { media } from 'common/utils/mixins';

import {
    addConceptInput,
    changeConsumptionRelationByPrice,
    changeTopValue,
    handleNormalizeUnitBuild,
    removeConceptInput,
} from '../../../helpers';

import PriceField from './PriceField';
const StyledCol = styled(Grid)`
    ${media.md`
        .form-label {
            display: none;
        }
    `}
`;

const ExcessLabel = styled(Typography)`
    background: #aaa;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 500;
    left: 9px;
    padding: 0 4px;
    position: absolute;
    top: -8px;
`;

const FieldArrayConcepts = ({
    control,
    disableActions = false,
    getValues,
    isConceptsOtherChargesTaxes,
    isContractedDemandConcept,
    isEnergyConcept,
    isHourlyRate,
    linkedConceptName,
    linkedConceptParentName,
    name,
    periodFieldName,
    setValue,
    showMode = false,
    topValueFieldEnabled,
    unitItems = [],
}) => {
    const { t } = useTranslation();
    const { append, fields, insert } = useFieldArray({ control, name });
    const { errors } = useFormState({ control, name });

    if (fields.length === 0 && showMode && isEnergyConcept)
        return (
            <Alert severity="warning" sx={{ mb: 2 }}>
                {t('You must add at least one charge')}
            </Alert>
        );

    const isHourlyLinkedConcept = linkedConceptParentName && isHourlyRate;
    const isHourlyField =
        (isHourlyRate && (isEnergyConcept || isContractedDemandConcept)) ||
        isHourlyLinkedConcept;
    const _disabled = showMode || (disableActions && isHourlyField);
    const showAlert = isEnergyConcept && fields.length === 0;

    const colSize = { sm: topValueFieldEnabled ? 6 : 16 / 3, xs: 18 };
    const hasErrors =
        !isUndefined(get(errors, name)) || !isEmpty(get(errors, name));

    return (
        <Stack direction="column">
            {showAlert && (
                <Alert severity="warning">
                    {t('You must add at least one charge')}
                </Alert>
            )}

            {fields.map((field, index) => {
                const isHelpConcept =
                    topValueFieldEnabled && index === fields.length - 1;
                const showDeleteButton =
                    !_disabled && !isHourlyLinkedConcept && !isHelpConcept;
                return (
                    <Box
                        backgroundColor={
                            isHelpConcept ? 'rgb(239 241 251 / 25%)' : null
                        }
                        borderRadius={1}
                        key={`${field.id}-box-${index}`}
                        p={1}
                    >
                        <Grid
                            alignItems="flex-start"
                            container
                            position="relative"
                            spacing={2}
                        >
                            {isHelpConcept && (
                                <ExcessLabel>{t('Excess')}</ExcessLabel>
                            )}

                            <StyledCol
                                display="flex"
                                gap={isHourlyField ? 1 : 0}
                                item
                                md
                                {...colSize}
                            >
                                {isHourlyField && (
                                    <Typography variant="body1" sx={{ pt: 1 }}>
                                        {index + 1}
                                    </Typography>
                                )}
                                <ReactHookFormInput
                                    control={control}
                                    disabled={
                                        _disabled || isHourlyLinkedConcept
                                    }
                                    label={t('Name')}
                                    name={`${name}.${index}.tag_name`}
                                    onBlur={(e) => {
                                        if (!linkedConceptName) return;
                                        const linkedConceptValues =
                                            getValues(linkedConceptName);
                                        if (linkedConceptValues?.length > 0)
                                            setValue(
                                                `${linkedConceptName}.${index}.tag_name`,
                                                e.target.value
                                            );
                                    }}
                                    placeholder={t('Name')}
                                />
                            </StyledCol>

                            {topValueFieldEnabled && (
                                <>
                                    <StyledCol item md {...colSize}>
                                        <ReactHookFormIntlNumberInput
                                            control={control}
                                            disabled
                                            label={t('Minimum', {
                                                context: 'male',
                                            })}
                                            name={`${name}.${index}.min_value`}
                                            placeholder={t('Minimum', {
                                                context: 'male',
                                            })}
                                        />
                                    </StyledCol>

                                    <StyledCol item md {...colSize}>
                                        <ReactHookFormIntlNumberInput
                                            allowDecimals={false}
                                            allowNegativeValue={false}
                                            control={control}
                                            disabled={showMode || isHelpConcept}
                                            label={t('Maximum', {
                                                context: 'male',
                                            })}
                                            name={`${name}.${index}.top_value`}
                                            onBlur={({ value }) =>
                                                changeTopValue({
                                                    fieldsName: name,
                                                    index,
                                                    newValue: value,
                                                    setValue,
                                                    values: getValues(name),
                                                })
                                            }
                                            placeholder={t('Maximum', {
                                                context: 'male',
                                            })}
                                        />
                                    </StyledCol>
                                </>
                            )}

                            <StyledCol item md {...colSize}>
                                <PriceField
                                    control={control}
                                    label={t('Quantity')}
                                    name={`${name}.${index}.price`}
                                    onChange={({ value }) => {
                                        if (isEnergyConcept)
                                            changeConsumptionRelationByPrice({
                                                conceptIndex: index,
                                                fieldsName: periodFieldName,
                                                getValues,
                                                setValue,
                                                value: value,
                                            });
                                    }}
                                    showMode={showMode}
                                    unitName={`${name}.${index}.unit`}
                                />
                            </StyledCol>

                            <StyledCol
                                item
                                {...colSize}
                                md
                                xs={showDeleteButton ? 14 : 18}
                            >
                                <ReactHookFormInput
                                    control={control}
                                    disabled={showMode}
                                    label={t('Unit')}
                                    name={`${name}.${index}.unit`}
                                    onChange={(e) =>
                                        handleNormalizeUnitBuild({
                                            fieldsName: name,
                                            index,
                                            onlyUnitEnabled:
                                                topValueFieldEnabled,
                                            setValue,
                                            value: e.target.value,
                                            values: getValues(name),
                                        })
                                    }
                                    placeholder={t('Unit')}
                                    select
                                >
                                    {unitItems?.map(
                                        ({ label, value, disabled }) => (
                                            <MenuItem
                                                disabled={disabled}
                                                key={`${field.id}-unit-select-${value}-${label}`}
                                                value={value}
                                            >
                                                {label}
                                            </MenuItem>
                                        )
                                    )}
                                </ReactHookFormInput>
                            </StyledCol>

                            <StyledCol
                                item
                                xs={4}
                                sm={2}
                                lg={1}
                                textAlign="right"
                            >
                                {showDeleteButton && (
                                    <Tooltip title={t('Delete')}>
                                        <IconButton
                                            key={`delete-button-${index}-${field.id}`}
                                            onClick={() => {
                                                if (isHourlyField)
                                                    setValue(
                                                        'settings_data.hourly_distribution.option_deleted',
                                                        { index, name }
                                                    );

                                                removeConceptInput({
                                                    conceptsFieldsName: name,
                                                    getValues,
                                                    indexToRemove: index,
                                                    isEnergyConcept,
                                                    isHourlyRate,
                                                    linkedConceptName,
                                                    periodFieldName,
                                                    setValue,
                                                    topValueFieldEnabled,
                                                });
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </StyledCol>
                        </Grid>
                    </Box>
                );
            })}

            {!_disabled && (
                <>
                    {isHourlyLinkedConcept && fields?.length ? (
                        <Button
                            color="error"
                            onClick={() => setValue(name, [])}
                            sx={{ mt: 2 }}
                            type="button"
                            variant="outlined"
                        >
                            {t('Delete')}
                        </Button>
                    ) : (
                        <Button
                            disabled={
                                (hasErrors && fields?.length > 0) ||
                                (isHourlyField && fields?.length >= 24)
                            }
                            onClick={() => {
                                addConceptInput({
                                    append,
                                    getValues,
                                    index: fields.length - 1,
                                    insert,
                                    isConceptsOtherChargesTaxes,
                                    isEnergyConcept,
                                    isHourlyRate,
                                    linkedConceptName,
                                    linkedConceptParentName,
                                    name,
                                    setValue,
                                    topValueFieldEnabled,
                                    unitItems,
                                });
                            }}
                            type="button"
                            sx={{ mt: 2 }}
                        >
                            {t('Add')}
                        </Button>
                    )}
                </>
            )}
        </Stack>
    );
};

FieldArrayConcepts.propTypes = {
    control: PropTypes.object,
    disableActions: PropTypes.bool,
    getValues: PropTypes.func,
    isConceptsOtherChargesTaxes: PropTypes.bool,
    isContractedDemandConcept: PropTypes.bool,
    isEnergyConcept: PropTypes.bool,
    isHourlyRate: PropTypes.bool,
    isMeasuredDemandConcept: PropTypes.bool,
    linkedConceptName: PropTypes.string,
    linkedConceptParentName: PropTypes.string,
    name: PropTypes.string,
    periodFieldName: PropTypes.string,
    setValue: PropTypes.func,
    showMode: PropTypes.bool,
    topValueFieldEnabled: PropTypes.bool,
    unitItems: PropTypes.array,
};

export default FieldArrayConcepts;
