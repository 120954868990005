import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { InputSearch } from 'common/components';
import AutocompleteField from 'common/components/AutocompleteField';
import { STATUS_CONFIG } from 'common/constants/afterSales';
import * as afterSalesProvidersSelectors from 'common/modules/afterSalesProviders/selectors';
import { Button, Grid } from 'common/ui';

const Filters = ({
    companiesForSelect,
    filters,
    isLoading,
    onChangeFilter,
    onFilter,
    providersForSelect,
}) => {
    const { t } = useTranslation();

    const statusOptions = Object.entries(STATUS_CONFIG).map(([key, value]) => ({
        label: t(value.label),
        value: key,
    }));

    return (
        <Grid alignItems="flex-end" container mb={2}>
            <Grid item xs={12} md={6}>
                <InputSearch
                    onChange={(e) =>
                        onChangeFilter('credentialName', e.target.value)
                    }
                    placeholder={t('Credential')}
                    value={filters?.credentialName}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <AutocompleteField
                    disabled={isLoading}
                    label={t('Company', { count: 1 })}
                    onChange={(value) => onChangeFilter('companyId', value)}
                    options={companiesForSelect}
                    value={filters.companyId}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <AutocompleteField
                    disabled={isLoading}
                    label={t('Provider')}
                    onChange={(value) => onChangeFilter('providerId', value)}
                    options={providersForSelect}
                    value={filters.providerId}
                />
            </Grid>

            <Grid item xs={12} sm={8} md={4} lg={5}>
                <AutocompleteField
                    disabled={isLoading}
                    label={t('Status')}
                    onChange={(value) => onChangeFilter('status', value)}
                    options={statusOptions}
                    value={filters.status}
                />
            </Grid>

            <Grid item lg={1} md={2} sm={4} xs={12}>
                <Button onClick={onFilter}>{t('Filter')}</Button>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    providersForSelect:
        afterSalesProvidersSelectors.getProvidersForSelectWithoutGroup,
});

Filters.propTypes = {
    companiesForSelect: PropTypes.array,
    filters: PropTypes.object,
    isLoading: PropTypes.bool,
    onChangeFilter: PropTypes.func,
    onFilter: PropTypes.func,
    providersForSelect: PropTypes.array,
};

export default connect(mapStateToProps)(Filters);
