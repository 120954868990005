import React, { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { BasicDataGrid, DropdownCardMenu } from 'common/components';
import { STATUS_CONFIG } from 'common/constants/afterSales';
import ProviderImage from 'common/modules/afterSalesProviders/components/ProviderImage';
import { transformDateFormat } from 'common/utils/dates';
import { getDateFormatByLocale } from 'common/utils/helpers/multiregion';

import * as actions from '../actions';
import { DEFAULT_PAGE_SIZE } from '../constants';
import * as selectors from '../selectors';

import Filters from './Filters';
import RewindModal from './RewindModal';
import StatusModal from './StatusModal';

const ListItems = ({
    companiesForSelect,
    fetchProjects,
    initialize,
    isLoading,
    projects,
    projectsPagination,
    redirect,
    setOpenRewindModal,
    setOpenStatusModal,
}) => {
    const [filters, setFilters] = useState({
        companyId: '',
        credentialName: '',
        providerId: '',
        status: '',
    });
    const { t } = useTranslation();
    const dateFormat = getDateFormatByLocale();

    useEffect(() => {
        fetchProjects({ page: 1, pageSize: DEFAULT_PAGE_SIZE });
    }, []);

    const onChangeFilter = (key, value) =>
        setFilters((prev) => ({ ...prev, [key]: value }));

    const onPageChange = (page) => {
        fetchProjects({
            ...filters,
            page: page + 1,
            pageSize: projectsPagination?.pageSize,
        });
    };

    const onPageSizeChange = (pageSize) => {
        fetchProjects({ ...filters, page: 1, pageSize });
    };

    const onRowClick = (row) => {
        if (!row?.id) return;
        redirect(`/after-sales-power-stations/${row.id}`);
    };

    const fetchAfterSave = () =>
        fetchProjects({
            companyId: filters.companyId || null,
            page: 1,
            pageSize: projectsPagination?.pageSize || DEFAULT_PAGE_SIZE,
        });

    const columns = [
        {
            field: 'provider',
            headerName: t('Provider'),
            renderCell: (params) => (
                <ProviderImage
                    providerId={params?.row?.provider_id}
                    style={{ maxHeight: '30px', maxWidth: '70px' }}
                />
            ),
            sortable: false,
            width: 100,
        },
        {
            field: 'power_station',
            flex: 3,
            headerName: t('Power station', { count: 1 }),
            minWidth: 200,
            sortable: false,
            valueGetter: (params) => params.row.name,
        },
        {
            field: 'credential',
            flex: 2,
            headerName: t('Credential'),
            minWidth: 200,
            sortable: false,
            valueGetter: (params) => params.row.company_sk_name,
        },
        {
            field: 'first_read',
            headerName: t('First read'),
            sortable: false,
            valueGetter: (params) =>
                transformDateFormat(
                    params.row?.first_read,
                    dateFormat,
                    'yyyy-MM-dd'
                ),
            width: 150,
        },
        {
            field: 'last_read',
            headerName: t('Last read'),
            sortable: false,
            valueGetter: (params) =>
                transformDateFormat(
                    params.row?.last_read,
                    dateFormat,
                    'yyyy-MM-dd'
                ),
            width: 150,
        },
        {
            field: 'status',
            headerName: t('Status'),
            renderCell: (params) => {
                const status = params.row.status;
                const config = STATUS_CONFIG[status];

                if (!config) return null;
                return (
                    <Typography variant="caption">
                        <span
                            style={{
                                backgroundColor: config.color,
                                borderRadius: '50%',
                                display: 'inline-block',
                                height: 10,
                                marginRight: 8,
                                width: 10,
                            }}
                        />
                        {t(config.label)}
                    </Typography>
                );
            },
            sortable: false,
            width: 150,
        },
        {
            field: 'actions',
            headerName: '',
            renderCell: (params) => (
                <DropdownCardMenu
                    items={[
                        {
                            handleClick: () => {
                                initialize(params.row);
                                setOpenStatusModal(true);
                            },
                            label: t('Change status'),
                        },
                        {
                            handleClick: () => {
                                initialize(params.row);
                                setOpenRewindModal(true);
                            },
                            label: t('Rewind project'),
                        },
                    ]}
                    sxButton={{ ml: 'auto' }}
                />
            ),
            width: 50,
        },
    ];

    return (
        <>
            <Filters
                companiesForSelect={companiesForSelect}
                filters={filters}
                isLoading={isLoading}
                onChangeFilter={onChangeFilter}
                onFilter={() =>
                    fetchProjects({
                        ...filters,
                        page: 1,
                        pageSize:
                            projectsPagination?.pageSize || DEFAULT_PAGE_SIZE,
                    })
                }
            />

            <BasicDataGrid
                columns={columns}
                isLoading={isLoading}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                onRowClick={onRowClick}
                page={projectsPagination?.page - 1}
                pageSize={projectsPagination?.pageSize}
                paginationMode="server"
                rowCount={projectsPagination?.total}
                rows={projects}
            />

            <RewindModal fetchProjects={fetchAfterSave} />

            <StatusModal fetchProjects={fetchAfterSave} />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    companiesForSelect: selectors.getCompaniesForSelect,
    isLoading: selectors.getProjectsIsLoading,
    projects: selectors.getProjects,
    projectsPagination: selectors.getProjectsPagination,
});

const mapDispatchToProps = (dispatch) => ({
    fetchProjects: (params) => dispatch(actions.fetchProjects(params)),
    initialize: (values) => dispatch(actions.initialize(values)),
    redirect: (link) => dispatch(push(link)),
    setOpenRewindModal: (isOpen) =>
        dispatch(actions.setOpenRewindModal(isOpen)),
    setOpenStatusModal: (isOpen) =>
        dispatch(actions.setOpenStatusModal(isOpen)),
});

ListItems.propTypes = {
    companiesForSelect: PropTypes.array,
    fetchProjects: PropTypes.func,
    initialize: PropTypes.func,
    isLoading: PropTypes.bool,
    projects: PropTypes.array,
    projectsPagination: PropTypes.object,
    redirect: PropTypes.func,
    setOpenRewindModal: PropTypes.func,
    setOpenStatusModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListItems);
